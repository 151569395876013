#page-landing{
    width: 100vw;
    height: 100vh;
    display:flex;
    justify-content: space-around;
    align-items: center;

    color: var(--color-text-in-primary);
    background: var(--color-primary);
}
.logo-conteiner img {
    height: 10rem;
}
.hero-image{
    width: 100%;
}
.logo-conteiner {
    text-align: center;
    margin-bottom: 3.2rem;
    text-align: left;
}
.logo-conteiner h2{
    font-weight: 500;
    font-size:2.4rem;
    line-height: 4.6rem;
    margin-top: 0.8rem;
}
.buttons-conteiner{
    display: flex;
    justify-content: center;
    margin: 3.2rem 0;
}
.buttons-conteiner a {
    width: 30rem;
    height: 10.4rem;
    border-radius: 0.8rem;
   
    font:700 2.0rem Archivo;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--color-button-text);
    transition: background-color 0.2s;
    }
    .buttons-conteiner a img{
        margin-right: 2.4rem;
    }
    .buttons-conteiner a:first-child{
        margin-right: 1.6rem;
    }
    .buttons-conteiner a img {
        width: 4rem;
    }

    .buttons-conteiner a.study{
        background: var(--color-primary-lighter);

    }
    .buttons-conteiner a.give-classes{
        background: var(--color-secundary);

    }
    .buttons-conteiner a.study:hover{
        background: var(--color-primary-ligh);

    }
    .buttons-conteiner a.give-classes:hover{
        background: var(--color-secundary-dark);

    }

    .total-connections {
        height: 100%;
        width: 100%;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        

    }
    .total-connections img {
    margin-left: 0.8rem;
    
    }


    @media (min-width:1000px){
        #page-landing-content{
            max-width: 1100px;
            display: grid;
            grid-template-rows: 400px 1fr;
            grid-template-columns: 2fr 1fr 1fr;
            grid-template-areas: "logo hero hero"
            "buttons buttons total";
        }
        .logo-conteiner{
            grid-area: logo;
            align-self: center;
            margin:0;
        }
        .logo-conteiner h2{
            text-align:initial ;
            font-size: 3.6rem;
        }
        .logo-conteiner img{
        height: 100%;
        }
        .hero-image{
            grid-area: hero;
            justify-self: end;
        }
        .buttons-conteiner{
            grid-area: buttons;
            justify-content: flex-start;

        }
        .buttons-conteiner a {
            font-size: 2.4rem;
        }
.total-connections{
    grid-area: total;
    display: flex;
    justify-self: end;
    align-items: center;
    justify-items: center;
    vertical-align: middle;
}
    }